import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// CSS
import 'styles/Contact.scss'

// Components
import Layout from 'components/Layout'
import {
  BannerDefault,
  TitleDefault,
  TitleAlt,
  TitleSubDefault,
  ContentDefault,
} from 'components/Elements'
import SEO from 'components/SEO'
import ContactForm from 'components/ContactForm'

// Images
import arrow from '../img/arrow-menu.svg'
import arrowWhite from '../img/arrow-white.svg'
import mailicon from '../img/mail-icon.svg'
import phoneicon from '../img/phone-icon.svg'
import linkedin from '../img/linkedin.svg'
import twitter from '../img/twitter.svg'

function ContactPage({ data }) {
  const { wordpressPage: page } = data

  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="contact-page">
        <BannerDefault>
          <div className="row">
            <Img
              className="position-absolute banner-image"
              fluid={page.acf.header.image.localFile.childImageSharp.fluid}
            />
            <div className="col-lg-3 pb-4">
              <TitleDefault>{page.acf.header.title}</TitleDefault>
            </div>
          </div>
        </BannerDefault>
        <section className="content-row">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 pt-5">
                <TitleAlt>
                  <img src={arrow} alt="" width="18" />
                  {page.acf.gegevens.title}
                </TitleAlt>
                <div className="row gegevens">
                  <div className="col-lg-6">
                    <p>
                      <img src={mailicon} width="32" alt="Mailen" />
                      <a
                        href={`mailto:${page.acf.gegevens.email}`}
                        className="link"
                      >
                        {page.acf.gegevens.email}
                      </a>
                    </p>
                    <p>
                      <img src={phoneicon} width="32" alt="Bellen" />
                      <a
                        href={`tel:${page.acf.gegevens.phone}`}
                        className="link"
                      >
                        {page.acf.gegevens.phone}
                      </a>
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <p>
                      <img src={linkedin} width="32" alt="LinkedIn" />
                      <a
                        href={page.acf.gegevens.linkedin}
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LinkedIn
                      </a>
                    </p>
                    <p>
                      <img src={twitter} width="32" alt="LinkedIn" />
                      <a
                        href={page.acf.gegevens.twitter}
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Twitter
                      </a>
                    </p>
                  </div>
                  <div className="col-12 pt-4">
                    <a
                      href={page.acf.gegevens.button.url}
                      className="btn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {page.acf.gegevens.button.text}
                      <img
                        src={arrow}
                        alt="arrow"
                        width="18"
                        className="button-img"
                      />
                    </a>
                  </div>
                </div>
                <div className="row about mt-5">
                  <div className="col-lg-12">
                    <TitleAlt>
                      <img src={arrow} alt="" width="18" />
                      {page.acf.aboutText.title}
                    </TitleAlt>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page.acf.aboutText.text,
                      }}
                    />
                    <a
                      href={page.acf.aboutText.button.url}
                      className="btn btn-transp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {page.acf.aboutText.button.text}
                      <img
                        src={arrow}
                        alt="arrow"
                        width="18"
                        className="button-img"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 d-md-block" />
              <div className="col-lg-4 sidebar-cta">
                <div className="sidebar-inner py-5 px-4">
                  <TitleSubDefault>
                    <img src={arrowWhite} alt="" width="18" />
                    {page.acf.form.title}
                  </TitleSubDefault>
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="empty-space-180" />
      </div>
    </Layout>
  )
}

export default ContactPage

export const ContactQuery = graphql`
  query Contact {
    wordpressPage(wordpress_id: { eq: 64 }) {
      title
      acf {
        header {
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1700) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        gegevens {
          title
          email
          phone
          linkedin
          twitter
          button {
            text
            url
          }
        }
        aboutText: about_text {
          title
          text
          button {
            text
            url
          }
        }
        form {
          title
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`
